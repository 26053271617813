<template>
  <div class="main-bg-color h-100vh">
    <div class="logo-image-container text-center login-header mx-3">
      <img src="@/assets/images/kerry-logo.jpg" alt="Kitten" class="logo-img" />
    </div>
    <div class="d-flex justify-content-center w-100 login-container">
      <div class="w-100 my-5">
        <div
          class="pb-4 text-center text-white font-weight-bold f-size-22"
          v-if="activeLanguage == 'th'"
        >
          <div>{{ $t("msgWelcome") }}</div>
          <div>{{ $t("msgKerryClub") }}</div>
        </div>
        <div
          class="pb-4 text-center text-white font-weight-bold f-size-22"
          v-else
        >
          <div>Welcome to KERRY CLUB</div>
        </div>
        <div class="d-flex justify-content-center">
          <div class="card p-3 mx-3 login-card">
            <div class="f-size-18 text-center">{{ $t("login") }}</div>
            <div class="pt-4">
              <InputText
                class="my-2 login-input"
                v-model="form.mobileNo"
                :textFloat="$t('mobileNo')"
                placeholder="xxx-xxx-xxxx"
                @onKeypress="isNumber($event)"
                type="tel"
                name="mobileNo"
                isRequired
                :isValidate="$v.form.mobileNo.$error"
                :v="$v.form.mobileNo"
              />
              <InputText
                class="my-2 login-input"
                v-model="form.password"
                :textFloat="$t('password')"
                :placeholder="$t('password')"
                type="password"
                name="password"
                isRequired
                @onKeypress="isNumber($event)"
                :isValidate="$v.form.password.$error"
                :v="$v.form.password"
                isShowPassword
              />
            </div>
            <div v-if="error != ''" class="m-2 text-center">
              <span class="text-danger w-100">
                {{ error }}
                <br />
                {{ " " }}
              </span>
            </div>
            <div class="pt-2">
              <b-button
                class="w-100 btn-main py-2"
                :disabled="isDisable"
                @click="checkForm"
                >{{ $t("login2") }}
                <b-spinner
                  class="align-middle w-1rem"
                  v-if="isLogin"
                ></b-spinner
              ></b-button>
            </div>
            <div class="py-4 text-center main-color f-size-14">
              <u
                class="pointer"
                @click="
                  $router.push(
                    '/forget-password/' +
                      activeLanguage +
                      '?sessionId=' +
                      sessionId
                  )
                "
                >{{ $t("forgotPassword") }}</u
              >
            </div>
            <div class="text-center pb-3">
              <span>{{ $t("noAccount") }}</span>
              <span
                class="font-weight-bold pointer"
                @click="
                  $router.push(
                    '/register/' + activeLanguage + '?sessionId=' + sessionId
                  )
                "
              >
                {{ $t("registerNow") }}</span
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import InputText from "@/components/input/InputText";
import {
  required,
  minLength,
  maxLength,
  numeric
} from "vuelidate/lib/validators";

export default {
  components: {
    InputText
  },
  data() {
    return {
      form: {
        mobileNo: "",
        password: "",
        userGUID: ""
      },
      redirectUrl: "",
      isDisable: false,
      isLogin: false,
      sessionId: null,
      error: "",
      activeLanguage: "th",
      lineProfile: {
        userId: "",
        displayName: "",
        pictureUrl: "",
        statusMessage: ""
      },
      userData: null
    };
  },
  validations() {
    return {
      form: {
        mobileNo: { required },
        password: {
          required,
          minLength: minLength(6),
          maxLength: maxLength(6),
          numeric
        }
      }
    };
  },
  watch: {
    "$route.fullPath": function () {
      if (this.$route.params.lang) {
        this.activeLanguage = this.$route.params.lang;
      }
    }
  },
  beforeCreate: async function () {
    if (this.$liff.isInClient()) {
      this.$liff
        .init({ liffId: this.$liff_Main_ID })
        .then(() => {
          this.$liff.getProfile().then(profile => {
            this.lineProfile = profile;
          });
        })
        .catch(error => {
          console.error("error", error);
        });
    }
  },
  created: async function () {
    this.sessionId = this.$route.query.sessionId;
    if (this.sessionId) {
      await this.getUserData();
    } else {
      this.$router.push("/session-expired");
    }
    if (this.$route.params.lang) {
      this.activeLanguage = this.$route.params.lang;
    }
  },
  methods: {
    getUserData: async function () {
      await this.$axios
        .get(this.$baseUrl + "/user/" + this.sessionId)
        .then(response => {
          if (response.data.result === 1) {
            if (response.data.detail.userGUID) {
              this.userData = response.data.detail;
              this.form.userGUID = response.data.detail.userGUID;
            } else {
              this.$router.push("/session-expired");
            }
          }
        });
    },
    checkForm: async function () {
      this.$v.form.$touch();
      if (this.$v.form.$error) return;
      await this.lgoin();
    },
    lgoin: async function () {
      this.isDisable = true;
      this.isLogin = true;
      this.error = "";
      await this.$axios
        .post(`${this.$baseUrl}/kerryapi/Login`, this.form)
        .then(data => {
          this.isDisable = false;
          if (data.data.result == 1) {
            this.isDisable = false;
            this.isLogin = false;
            this.$cookies.set(
              "kerry-member-token",
              data.data.detail.memberToken,
              60 * 60 * 24 * 30
            );
            this.checkBeforeCloseApp();
          } else {
            this.isLogin = false;
            this.error = data.data.message;
          }
        });
    },
    onCloseLiffApp() {
      this.$liff.closeWindow();
    },
    checkBeforeCloseApp() {
      const queryStringEncode = encodeURIComponent(window.location.search);
      const queryStringDecode = decodeURIComponent(queryStringEncode);

      const params = new URLSearchParams(queryStringDecode);
      const redirectUrl = params.get("redirectUrl");

      if (redirectUrl) {
        this.$router.push(`${redirectUrl}`);
      } else {
        if (this.$liff.isInClient()) {
          this.onCloseLiffApp();
        } else {
          setTimeout(() => {
            window.close();
          }, 1000);
          //this.$router.push(`/success/login`);
        }
      }
    },
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        evt.preventDefault();
      } else {
        return true;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.logo-img {
  width: 100px;
  height: auto;
  text-align: center;
}
.login-header {
  height: 60px;
  border-bottom: 2px solid white;
}
.login-container {
  height: calc(100vh - 60.5px);
}
.login-card {
  width: 100%;
}
::v-deep .card {
  border-color: white;
  border-radius: 14px;
}

.w-1rem {
  width: 1rem;
  height: 1rem;
}

.button-eye {
  position: absolute;
  right: 10px;
  top: 0.8rem;
  z-index: 6;
}
@media only screen and (min-width: 767.98px) {
  .login-card {
    max-width: 450px;
  }
}
</style>